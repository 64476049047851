import ShipmentListDto from "../models/ShipmentListDto";
import { apiUrl } from "../utils/BaseUrl";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import ShipmentItemDto from "../models/ShipmentItemDto";
import { useContext } from "react";
import ImportEtaDetailUpdateDto from "../models/ImportEtaDetailUpdateDto";
import GridServerSideModel from "../models/dataGrid/GridServerSideModel";

const receivingQueryKey: string = "receiving";

export function useGetTodaysShipments<TQueryFnData = ShipmentListDto[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([receivingQueryKey], ({ signal }) => fetchGet(`${apiUrl}/eta/v1/${receivingQueryKey}/todayrange`, signal), options);
}

export function useGetShipments<TQueryFnData = ShipmentListDto[], TError = string, TData = TQueryFnData>(serverSideModel: GridServerSideModel, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    let queryParams = new URLSearchParams(serverSideModel as {}).toString(); 
    return useQuery([receivingQueryKey], ({ signal }) => fetchPost(`${apiUrl}/eta/v1/${receivingQueryKey}?${queryParams}`, signal), options);
}

const etaTableDetailQueryKey: string = "etatabledetail";

export function useGetShipmentItems<TQueryFnData = ShipmentItemDto[], TError = string, TData = TQueryFnData>(importNumber: string, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([etaTableDetailQueryKey, importNumber], ({ signal }) => fetchGet(`${apiUrl}/eta/v1/${etaTableDetailQueryKey}/${importNumber}`, signal), options);
}

interface UseUpdateEtaDetailItemsOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateEtaDetailItems(options?: UseUpdateEtaDetailItemsOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: ImportEtaDetailUpdateDto[]) => fetchPut(`${apiUrl}/eta/v1/${etaTableDetailQueryKey}`, model), {
        onMutate: async (model) => {
            //await queryClient.cancelQueries({ queryKey: [etaTableDetailQueryKey] });
            //await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            //const previousDataList = queryClient.getQueryData<TodoItem[]>([queryKey]);
            //if (previousDataList)
            //    queryClient.setQueryData([queryKey], previousDataList.map(prev => prev.id === model.id ? model : prev));

            //const previousData = queryClient.getQueryData<TodoItem>([queryKey, model.id]);
            //if (previousData)
            //    queryClient.setQueryData([queryKey, model.id], model);

            //return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            //if (context) {
            //    queryClient.setQueryData([queryKey], context.previousDataList);
            //    queryClient.setQueryData([queryKey, variables.id], context.previousData);
            //}

            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
}