import { AllInbox, Home } from "@mui/icons-material";
import { blueGrey, brown } from "@mui/material/colors";
import { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LayoutProvider, LeftDrawerItem, useUser } from "wcz-layout";
import packageJson from "../package.json";
import DetailPage from "./pages/DetailPage";
import HomePage from "./pages/HomePage";
import ReceivedMaterialPage from "./pages/ReceivedMaterialPage";
import { fileServiceUrl } from "./utils/BaseUrl";
import { FileProvider } from "wcz-file";

export default function App() {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "Received Material", path: "/received-material", icon: <AllInbox fontSize="large" /> },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title="WH Receiving" appVersion={packageJson.version} primaryColor={brown[500]} secondaryColor={blueGrey[500]} leftDrawerItems={leftDrawerItems}>
                <FileProvider appName="wh-receiving-ui" baseUrl={fileServiceUrl}>
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/detail/:importNumber/:hawb' element={<DetailPage />} />
                        <Route path='/received-material' element={<ReceivedMaterialPage />} />
                    </Routes>
                </FileProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
};