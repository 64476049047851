import { AddAPhoto, ForwardToInbox } from "@mui/icons-material";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FileViewer, useFile } from "wcz-file";
import { LayoutContext, newGuid } from "wcz-layout";
import CreateReceivingDto from "../../models/CreateReceivingDto";
import { DamageType } from "../../models/enums/DamageType";
import { useCreateReceiving } from "../../services/WhReceivingService";

interface CreateDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
}

export default function CreateDialog(props: CreateDialogProps) {
    const { open, setOpen } = props;
    const { user } = useContext(LayoutContext);
    const [receiving, setReceiving] = useState({} as CreateReceivingDto);
    const [attachmentsId, setAttachmentsId] = useState("");

    useEffect(() => {
        if (open) {
            setReceiving({
                materialDescription: "",
                receiveDate: moment().format("YYYY-MM-DD"),
                receiveTime: moment().format("HH:mm"),
                keeper: user.name,
                importNumber: "",
                hawb: "",
                lastUpdatedDate: moment().toISOString(true),
                lastUpdatedBy: user.id,
                notificationGroups: [] as string[]
            } as CreateReceivingDto);

            setAttachmentsId(newGuid);
        }
    }, [open]);

    function cleanForm() {
        setAttachmentsId("");
        setReceiving({} as CreateReceivingDto);
        //deleteAllFiles();
    }

    function handleClickCancelDialog() {        
        cleanForm();
        setOpen(false);
    };

    const { uploadProgress, uploadFiles } = useFile({ subId: attachmentsId, onFileUpload: () => setReceiving({ ...receiving, attachmentId: attachmentsId }) });

    const { mutate: saveNewReceiving } = useCreateReceiving();

    function handleClickSave() {
        saveNewReceiving([receiving]);
        cleanForm();
        setOpen(false);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Add manual receiving</DialogTitle>
            <DialogContent>
                <TextField id="cpn"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="CPN"
                    value={receiving?.cpn ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, cpn: event.target.value });
                    }}
                />
                <TextField id="quantity"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="Quantity"
                    value={receiving?.quantity ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, quantity: +event.target.value });
                    }}
                />
                <TextField id="receiveDate"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="Receive Date"
                    value={receiving?.receiveDate ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, receiveDate: event.target.value });
                    }}
                />
                <TextField id="receiveTime"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="Receive Time"
                    value={receiving?.receiveTime ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, receiveTime: event.target.value });
                    }}
                />
                <TextField id="keeper"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="Keeper"
                    value={receiving?.keeper ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, keeper: event.target.value });
                    }}
                />
                <FormControl variant="standard" sx={{ mt: "10px" }} fullWidth>
                    <InputLabel id="damage" sx={{ color: "#757575" }}>Damage of</InputLabel>
                    <Select id="damage"
                        type="text" fullWidth
                        value={receiving.damageOf ?? ""}
                        onChange={(event) => {
                            setReceiving({ ...receiving, damageOf: event.target.value ?? undefined });
                        }}
                    >
                        <MenuItem value="">None</MenuItem>
                        {Object.values(DamageType).map((damage) => <MenuItem key={damage} value={damage}>{damage}</MenuItem>)}
                    </Select>
                </FormControl>  
                <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                    <ForwardToInbox fontSize="medium" sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                    <Autocomplete id="emailNotificationTo" sx={{ mt: "10px" }} multiple fullWidth
                        options={["Logistics", "Buyers", "IQC"]}
                        value={receiving.notificationGroups ?? []}
                        onChange={(event: any, newValue: string[]) => {
                            setReceiving({ ...receiving, notificationGroups: newValue });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Send E-mail notification To"
                            />
                        )}
                    />
                </Box>                
                {
                    receiving.attachmentId &&
                    <Box sx={{ mt: "30px" }}>
                        <Typography variant="body1" color="text.secondary" gutterBottom component="div">Photos</Typography>
                        <FileViewer subId={receiving.attachmentId} />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <IconButton component="label" disabled={!!uploadProgress} sx={{ marginRight: "auto", marginLeft: "15px" }}>
                    <input hidden accept="*" type="file" onChange={uploadFiles} />
                    <AddAPhoto />
                </IconButton>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleClickSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}