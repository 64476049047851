import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import CreateReceivingDto from "../models/CreateReceivingDto";
import GridServerSideModel from "../models/dataGrid/GridServerSideModel";
import PalletDto from "../models/PalletDto";
import ReceivingsContentModel from "../models/ReceivingsContentModel";
import ReceivingsListDto from "../models/ReceivingsListDto";
import ReceivingUpdateDto from "../models/ReceivingUpdateDto";
import { apiUrl } from "../utils/BaseUrl";
import BoxDto from "../models/BoxDto";
import { Box } from "@mui/material";

const serviceUrlPrefix: string = "wh-receiving";

const receivingQueryKey: string = "receiving";

export function useGetReceiving<TQueryFnData = ReceivingsListDto, TError = string, TData = TQueryFnData>(id: string, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([receivingQueryKey, id], ({ signal }) => fetchGet(`${apiUrl}/${serviceUrlPrefix}/v1/${receivingQueryKey}/${id}`, signal), options);
}

export function useGetReceivings<TQueryFnData = ReceivingsContentModel, TError = string, TData = TQueryFnData>(serverSideModel: GridServerSideModel, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {    
    let queryParams = new URLSearchParams(serverSideModel as {}).toString();
    return useQuery([receivingQueryKey], ({ signal }) => fetchGet(`${apiUrl}/${serviceUrlPrefix}/v1/${receivingQueryKey}?${queryParams}`, signal), options);
}

interface UseCreateReceivingOptions {
    onSuccess?: (data: CreateReceivingDto[]) => void,
    onError?: (message: string) => void,
}

export function useCreateReceiving(options?: UseCreateReceivingOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: CreateReceivingDto[]) => fetchPost(`${apiUrl}/${serviceUrlPrefix}/v1/${receivingQueryKey}`, model), {
        onMutate: async (model) => {
            //await queryClient.cancelQueries({ queryKey: [receivingQueryKey] });
            //await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            //const previousDataList = queryClient.getQueryData<TodoItem[]>([queryKey]);
            //if (previousDataList)
            //    queryClient.setQueryData([queryKey], [model, ...previousDataList]);

            //const previousData = queryClient.getQueryData<TodoItem>([queryKey, model.id]);
            //if (previousData)
            //    queryClient.setQueryData([queryKey, model.id], model);

            //return { previousDataList, previousData };
        },
        onError: (err: string, model, context) => {
            //if (context) {
            //    queryClient.setQueryData([queryKey], context.previousDataList);
            //    queryClient.setQueryData([queryKey, model.id], context.previousData);
            //}

            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model, variables, context) => {
            //if (context) {
            //    queryClient.setQueryData([queryKey], [model, ...context.previousDataList ?? []]);
            //    queryClient.setQueryData([queryKey, variables.id], model);
            //}

            if (options?.onSuccess)
                options.onSuccess(model);
        },
        onSettled: () => {
        // Always refetch after error or success:
            queryClient.invalidateQueries({ queryKey: [receivingQueryKey] });
        },
    });
}

interface UseUpdateReceivingOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateReceiving(options?: UseUpdateReceivingOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: ReceivingUpdateDto) => fetchPut(`${apiUrl}/${serviceUrlPrefix}/v1/${receivingQueryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [receivingQueryKey] });
            await queryClient.cancelQueries({ queryKey: [receivingQueryKey, model.id] });

            const previousDataList = queryClient.getQueryData<ReceivingsContentModel>([receivingQueryKey]);
            if (previousDataList?.content)
                queryClient.setQueryData([receivingQueryKey], { ...previousDataList, content: previousDataList.content.map(prev => prev.id === model.id ? {...prev, grNumber: model.grNumber, iqcStatus: model.iqcStatus, remark: model.remark, damageOf: model.damageOf, attachmentId: model.attachmentId } : prev) } );

            const previousData = queryClient.getQueryData<ReceivingsListDto>([receivingQueryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([receivingQueryKey, model.id], { ...previousData, grNumber: model.grNumber, iqcStatus: model.iqcStatus, remark: model.remark, damageOf: model.damageOf, attachmentId: model.attachmentId });

            return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([receivingQueryKey], context.previousDataList);
                queryClient.setQueryData([receivingQueryKey, variables.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

const scanQueryKey: string = "scan";

export function useGetPallets<TQueryFnData = PalletDto[], TError = string, TData = TQueryFnData>(hawb: string, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([scanQueryKey, hawb], ({ signal }) => fetchGet(`${apiUrl}/${serviceUrlPrefix}/v1/${scanQueryKey}/pallet/${hawb}`, signal), options);
}

interface UseUpdatePalletOptions {
    onSuccess?: (data: PalletDto) => void,
    onError?: (message: string) => void,
}

export function useUpdatePallet(hawb: string, options?: UseUpdatePalletOptions) {
    const queryClient = useQueryClient();

    return useMutation((model: PalletDto) => fetchPost(`${apiUrl}/${serviceUrlPrefix}/v1/${scanQueryKey}/pallet/${model.palletId}`, null), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [scanQueryKey, hawb] });
           
            const previousDataList = queryClient.getQueryData<PalletDto[]>([scanQueryKey, hawb]);
            if (previousDataList)
                queryClient.setQueryData([scanQueryKey, hawb], previousDataList.map(prev => prev.palletId === model.palletId ? model : prev));
            
            return { previousDataList };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([scanQueryKey, hawb], context.previousDataList);
            }

            if (options?.onError)
                options.onError(err);
        },
        onSuccess: (model) => {
            if (options?.onSuccess)
                options.onSuccess(model);
        }
    });
}

export function useGetBoxes<TQueryFnData = BoxDto[], TError = string, TData = TQueryFnData>(palletId: string, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([scanQueryKey, palletId], ({ signal }) => fetchGet(`${apiUrl}/${serviceUrlPrefix}/v1/${scanQueryKey}/boxes/${palletId}`, signal), options);
}

interface UseUpdateBoxOptions {
    onSuccess?: (data: BoxDto) => void,
    onError?: (message: string) => void
}

export function useUpdateBox(palletId: string, options?: UseUpdateBoxOptions) {
    const queryClient = useQueryClient();

    return useMutation((model: BoxDto) => fetchPost(`${apiUrl}/${serviceUrlPrefix}/v1/${scanQueryKey}/boxes/${palletId}/${model.boxId}`, null), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [scanQueryKey, palletId] });

            const previousDataList = queryClient.getQueryData<BoxDto[]>([scanQueryKey, palletId]);
            if (previousDataList)
                queryClient.setQueryData([scanQueryKey, palletId], previousDataList.map(prev => prev.boxId === model.boxId ? model : prev));

            return { previousDataList };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([scanQueryKey, palletId], context.previousDataList);
            }

            if (options?.onError)
                options.onError(err);
        },
        onSuccess: (model) => {
            if (options?.onSuccess)
                options.onSuccess(model);
        }
    });
}

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteBox(palletId: string, options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((boxId: string) => fetchDelete(`${apiUrl}/${serviceUrlPrefix}/v1/${scanQueryKey}/boxes/${boxId}`), {
        onMutate: async (boxId) => {
            await queryClient.cancelQueries({ queryKey: [scanQueryKey, palletId] });

            const previousData = queryClient.getQueryData<BoxDto[]>([scanQueryKey, palletId]);

            if (previousData) {
                queryClient.setQueryData([scanQueryKey, palletId], previousData.filter(prev => prev.boxId !== boxId));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([scanQueryKey, palletId], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseConfirmBoxesScanningOptions {
    onSuccess?: (data: BoxDto[]) => void,
    onError?: (message: string) => void
}

export function useConfirmBoxesScanning(palletId: string, options?: UseConfirmBoxesScanningOptions) {
    const queryClient = useQueryClient();

    return useMutation(() => fetchPost(`${apiUrl}/${serviceUrlPrefix}/v1/${scanQueryKey}/boxes/confirm/${palletId}`, null), {
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [scanQueryKey, palletId] });

            const previousDataList = queryClient.getQueryData<BoxDto[]>([scanQueryKey, palletId]);
            if (previousDataList)
                queryClient.setQueryData([scanQueryKey, palletId], previousDataList.map(prev => !prev.boxIdScanDate ? {...prev, boxIdRemark: "missing"} : prev));

            return { previousDataList };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([scanQueryKey, palletId], context.previousDataList);
            }

            if (options?.onError)
                options.onError(err);
        },
        onSuccess: (model) => {
            if (options?.onSuccess)
                options.onSuccess(model);
        }
    });
}
