import { isDevelopment } from "wcz-layout";

let apiUrl: string = "https://api.dev.wistron.eu";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu";
}

let fileServiceUrl: string = isDevelopment ? "https://api.dev.wistron.eu/file" : "https://api.wistron.eu/file";

export { apiUrl, fileServiceUrl };