import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FileViewer } from "wcz-file";


interface ViewPhotosDialogProps {
    attachmentsId: string,
    setAttachmentsId: (attachmentsId: string) => void,
}

export default function ViewPhotosDialog(props: ViewPhotosDialogProps) {
    const { attachmentsId, setAttachmentsId } = props;

    function handleClickCancelDialog() {
        setAttachmentsId("");
    };

    return (
        <Dialog open={!!attachmentsId} fullWidth>
            <DialogTitle>Attached photos</DialogTitle>
            <DialogContent>                
                {
                    attachmentsId &&
                    <FileViewer subId={attachmentsId} />                    
                }
            </DialogContent>
            <DialogActions>             
                <Button onClick={handleClickCancelDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}