import { DocumentScanner } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardActions, CardContent, List, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext, newGuid } from "wcz-layout";
import ScanningPalletDialog from "../components/detailPage/ScanningPalletDialog";
import ShipmentDetailListItem from "../components/detailPage/ShipmentDetailListItem";
import CreateReceivingDto from "../models/CreateReceivingDto";
import ImportEtaDetailUpdateDto from "../models/ImportEtaDetailUpdateDto";
import PalletDto from "../models/PalletDto";
import ShipmentItemDto from "../models/ShipmentItemDto";
import { useGetShipmentItems, useUpdateEtaDetailItems } from "../services/EtaService";
import { useCreateReceiving, useGetPallets } from "../services/WhReceivingService";
import ScanningBoxesDialog from "../components/detailPage/ScanningBoxesDialog";

export default function DetailPage() {
    const { importNumber, hawb } = useParams();
    const { user } = useContext(LayoutContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [receiveButtonText, setReceiveButtonText] = useState("Receive all");
    const [isSaving, setIsSaving] = useState(false);

    const [pallets, setPallets] = useState<PalletDto[]>([]);
    useGetPallets(hawb!, { onSuccess: (data) => setPallets(data), onError: () => { } });
    const palletTableColumns: GridColDef[] = useMemo(() => [
        { field: "palletId", headerName: "Pallet Id", width: 200 },
        { field: "palletIdScanDate", headerName: "Date Scanned", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss") : "", width: 200 },
    ] as GridColDef[], [pallets]);

    const [isScanningPalletId, setIsScanningPalletId] = useState(false);
    function handleClickScanPalletId() {
        setIsScanningPalletId(true);
    }

    const [palletIdToOpenScanningBoxes, setPalletIdToOpenScanningBoxes] = useState("");

    const { data: shipmentItems = [] as ShipmentItemDto[], isLoading } = useGetShipmentItems(importNumber!);
    const [selectedItems, setSelectedItems] = useState([] as ShipmentItemDto[]);

    const onItemSelectionChanged = (isSelected: boolean, item: ShipmentItemDto) => {

        if (isSelected) {
            setSelectedItems([...selectedItems, item]);
        }
        else {
            let selectedItemsNew: ShipmentItemDto[] = selectedItems.filter(i => i.subItem !== item.subItem);
            setSelectedItems(selectedItemsNew);
        }
    };

    useEffect(() => {
        setReceiveButtonText("Receive selected (" + selectedItems.length + ")");

        if (selectedItems.length === shipmentItems.filter(item => !item.receivingCompleted).length || selectedItems.length === 0) {
            setReceiveButtonText("Receive all");
        }
    }, [selectedItems, shipmentItems]);

    const setShipmentItem = (newItem: ShipmentItemDto) => {
        // Snapshot the previous value
        const previous = queryClient.getQueryData<ShipmentItemDto[]>(["etatabledetail", importNumber]) ?? [] as ShipmentItemDto[];

        let idx = previous.findIndex(i => i.subItem === newItem.subItem);
        let updatedShipmentItems = Array.from(previous);
        updatedShipmentItems[idx] = newItem;

        // Optimistically update to the new value
        queryClient.setQueryData(["etatabledetail", importNumber], () => updatedShipmentItems);
    };

    const { mutate: updateEtaDetailItems } = useUpdateEtaDetailItems({
        onSuccess: () => {
            setIsSaving(false);
            navigate("/");
        },
        onError: () => setIsSaving(false)
    });

    const { mutate: saveNewReceivingsAndUpdateEtaDetailItems } = useCreateReceiving({
        onSuccess: () => {
            let importEtaDetailsItems: ImportEtaDetailUpdateDto[] = shipmentItems.filter(item => (selectedItems.length === 0 && !item.receivingCompleted) || (selectedItems.length > 0 && selectedItems.some(selected => selected.subItem === item.subItem)))
                .map((item) => {
                    return {
                        importNumber: importNumber as string,
                        subItem: item.subItem,
                        receivedQuantity: item.remainingQuantity
                    };
                });

            updateEtaDetailItems(importEtaDetailsItems);
        },
        onError: () => setIsSaving(false)
    });

    const submit = () => {
        setIsSaving(true);

        let receivingItems: CreateReceivingDto[] = shipmentItems.filter(item => (selectedItems.length === 0 && !item.receivingCompleted) || (selectedItems.length > 0 && selectedItems.some(selected => selected.subItem === item.subItem)))
            .map((item) => {
                return {
                    cpn: item.material,
                    materialDescription: item.materialDescription,
                    quantity: item.remainingQuantity,
                    receiveDate: moment().format("YYYY-MM-DD"),
                    receiveTime: moment().format("HH:mm"),
                    keeper: user.name,
                    importNumber: importNumber ? importNumber.toString() : "",
                    hawb: hawb ? hawb.toString() : "",
                    lastUpdatedBy: user.id,
                    lastUpdatedDate: Date.now().toString(),
                    attachmentId: undefined,
                    notificationGroups: [],
                    damageOf: undefined
                };
            });

        saveNewReceivingsAndUpdateEtaDetailItems(receivingItems);
    };

    return (
        <>
            {
                pallets.length > 0 &&
                <Card sx={{ m: "5px" }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                            Pallets
                        </Typography>
                        <Box sx={{
                            display: "flex", width: "100%",
                            "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "600" },
                        }}>
                            <DataGridPro columns={palletTableColumns} rows={pallets}
                                sx={{ padding: "10px 20px", border: "0px", minHeight: "170px", maxHeight: "30vh" }}
                                getRowId={() => newGuid()}
                                density="compact"
                                hideFooter
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button startIcon={<DocumentScanner />} onClick={handleClickScanPalletId}>
                            Scan pallet id
                        </Button>
                    </CardActions>
                        <ScanningPalletDialog open={isScanningPalletId} setOpen={setIsScanningPalletId} pallets={pallets} setPallets={setPallets} hawb={hawb!} setPalletIdToOpenScanningBoxesDialog={setPalletIdToOpenScanningBoxes} />
                        <ScanningBoxesDialog palletId={palletIdToOpenScanningBoxes} setPalletId={setPalletIdToOpenScanningBoxes} setScanningPalletDialogOpen={setIsScanningPalletId} />
                </Card>
            }
            <Card sx={{ m: "5px" }}>
                <CardContent>
                    <Typography color="textSecondary" sx={{ fontSize: 14 }} display="inline">
                        Import # &nbsp;
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {importNumber}
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: 14 }} color="textSecondary" display="inline">
                        HAWB &nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {hawb}
                    </Typography>
                </CardContent>
            </Card>

            {
                (pallets.length === 0 || pallets.every(p => !!p.palletIdScanDate)) ?
                    <Card sx={{ m: "5px" }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                Details ({shipmentItems.length} items)
                            </Typography>

                            <List>
                                {
                                    isLoading &&
                                    <ListItem key={0}>
                                        <ListItemText
                                            primary={<><Skeleton animation="wave" height={40} /></>}
                                            secondary={<><Skeleton animation="wave" /></>}
                                        />
                                    </ListItem>
                                }
                                {
                                    shipmentItems.map((item: ShipmentItemDto) =>
                                        <ShipmentDetailListItem
                                            key={item.subItem}
                                            item={item}
                                            importNumber={importNumber as string}
                                            onSelectionChanged={onItemSelectionChanged}
                                            setShipmentItem={setShipmentItem}
                                        />
                                    )
                                }
                            </List>

                            <Button sx={{ marginTop: "20px" }} variant="contained" color="primary" fullWidth disabled={isLoading || isSaving} onClick={() => submit()}>
                                {receiveButtonText}
                            </Button>
                        </CardContent>
                    </Card> :
                    <Box sx={{ p: "10px"} }>
                        <Alert severity="info">First scan all the pallets and then you can continue with the receiving.</Alert> 
                    </Box>
            }
        </>
    );
}