import { DocumentScanner } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import PalletDto from "../../models/PalletDto";
import { useUpdatePallet } from "../../services/WhReceivingService";
import useScanDetection from "../../utils/UseScanDetection";

interface ScanningPalletDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    pallets: PalletDto[],
    setPallets: (pallets: PalletDto[]) => void,
    hawb: string,
    setPalletIdToOpenScanningBoxesDialog: (palletId: string) => void,
}

export default function ScanningPalletDialog(props: ScanningPalletDialogProps) {
    const { user } = useContext(LayoutContext);
    const { open, setOpen, pallets, setPallets, hawb, setPalletIdToOpenScanningBoxesDialog } = props;
    //const [lastScannedPalletId, setLastScannedPalletId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    function cleanForm() {
        //setLastScannedPalletId("");
        setErrorMessage("");
    };

    function handleClickCancelDialog() {
        cleanForm();
        setOpen(false);
    };

    useScanDetection({
        //timeToEvaluate: 1000,
        //averageWaitTime: 1000,
        enabled: open,
        onComplete: value => handleOnScan(value)
    });

    const { mutate: updatePallet } = useUpdatePallet(hawb, {
        onSuccess: (data) => {
            setErrorMessage("");
            //setLastScannedPalletId(data.palletId);
            setPallets(pallets.map(prev => prev.palletId === data.palletId ? data : prev));
            handleClickCancelDialog();
            setPalletIdToOpenScanningBoxesDialog(data.palletId);
        },
        onError: (errorMessage) => {
            setErrorMessage(errorMessage);
        }
    });

    const handleOnScan = useCallback((value: string) => {
        if (pallets.some(p => p.palletId === value)) {
            let updatedPallet: PalletDto = {
                palletId: value,
                palletIdScanDate: moment().format("yyyy-MM-DD HH:mm"),
                palletIdScannedBy: `${user.name} (${user.id})`
            };

            updatePallet(updatedPallet);  
        }
        else {
            setErrorMessage(`The scanned pallet ID '${value}' does NOT match!`);
        }
    }, []);

    return (
        <Dialog open={open}>
            <DialogTitle><DocumentScanner sx={{ mb: "-3px" }} /> Please scan a pallet ID...</DialogTitle>
            <DialogContent>
                <Typography color="error">{errorMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClickCancelDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}