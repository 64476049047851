import { Box, Checkbox, Grid, InputAdornment, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import ShipmentItemDto from "../../models/ShipmentItemDto";

interface ShipmentDetailListItemProps {
    item: ShipmentItemDto,
    importNumber: string
    onSelectionChanged: (isSelected: boolean, item: ShipmentItemDto) => void,
    setShipmentItem: (item: ShipmentItemDto) => void
}

export default function ShipmentDetailListItem(props: ShipmentDetailListItemProps) {
    const { item, importNumber, onSelectionChanged, setShipmentItem } = props;

    const updateRemainingQty = (subItem: number, value: number, maxValue: number) => {
        if (value < 0) return;
        if (value > maxValue) return;

        setShipmentItem({ ...item, remainingQuantity: value });
    };

    return (
        <ListItem key={item.subItem + item.purchasingDocument} sx={{ flexWrap: "wrap", color: item.receivingCompleted ? "#20C997!important" : "" }}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    tabIndex={-1}
                    sx={{ display: item.receivingCompleted ? "none" : "" }}
                    onChange={(event) => onSelectionChanged(event.target.checked, item)}
                />
                <Box component="span" sx={{ marginLeft: item.receivingCompleted ? "28px" : "", color: item.receivingCompleted ? "#20C997!important" : "" }}>
                    {item.subItem}
                </Box>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container sx={{ flexGrow: 1 }}>
                        <Grid item xs={7}>
                            <span>{item.material}</span>
                            <br />
                            <span style={{ fontSize: 12, opacity: 0.7 }}>
                                {"PO: " + item.purchasingDocument + "; item: " + item.item}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={item.remainingQuantity}
                                helperText={
                                    <Box component="span" sx={{ color: item.receivingCompleted ? "#20C997!important" : "" }}>
                                        Rcvd {item.receivedQuantity}/{item.orderedQuantity}
                                    </Box>
                                }
                                type="number"
                                size="small"
                                variant="standard"
                                sx={{ WebkitTextFillColor: item.receivingCompleted ? "#20C997!important" : "" }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Box component="span" sx={{ color: item.receivingCompleted ? "#20C997!important" : "" }}>
                                                pcs
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => updateRemainingQty(item.subItem, +e.target.value, item.orderedQuantity - item.receivedQuantity)}
                            />
                        </Grid>
                    </Grid>
                }
            />

        </ListItem>
    );
}